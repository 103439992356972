<template>
  <div class="manageBarWrap">
   <div v-for="(item,index) in itemData.list" :key="index" class="btnItem" @click="manageBtnClick(index,item)">
     <template v-if="item.name =='分享'">
       <template v-if="pageModeType && pageModeType === 'editPage'">
          <button class="fxBtn">
          <image :src="item.icon" class="btnIcon" />{{item.name}}
        </button>
       </template>
       <template v-else>
          <button open-type='share' class="fxBtn">
            <image :src="item.icon" class="btnIcon" />{{item.name}}
          </button>
       </template>
     </template>
     <template v-else>
      <image :src="item.icon" class="btnIcon" />
      {{ item.name }}
     </template>

   </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    props: ['itemData','pageModeType'],
    methods:{
      manageBtnClick(index, item) {
        if(item.name === '管理') {
          this.$emit('goManage')
        }
      }
    }
  }
</script>
<style scoped>
.manageBarWrap{
  width: 279px;
  height: 34px;
  background: #FAFCFF;
  border: 1px solid #DFE7EE;
  border-radius: 17px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2C3034;
  align-items: center;
    margin: 20px auto;
}
.manageBarWrap .btnItem{
  border-right: 1px solid #DFE7EE;
  padding-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.manageBarWrap .btnItem:last-child{
  border: none;
}
.manageBarWrap .btnIcon{
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.fxBtn{
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 29px;
  background: transparent;
}
</style>