import {getStorage} from "@/utils/utils";

// export  let typeMap={'state':0,'title':1,'btn':2,'table': 3,'content':4,'pic':5,'txt':6,"record": 7,'footer': 8, 'manageBar': 9}


export let componentTypeMap = {1: '状态', 2: '标题', 3: '按钮', 4: '表格', 5: '媒体', 6: '文本', 7: '动态档案'}

export let typeMap = {
    'state': 0,
    'title': 1,
    'btn': 2,
    'table': 3,
    'pic': 4,
    'txt': 5,
    "record": 6,
    'footer': 7,
    'manageBar': 8
}

export let tableTypeMap = {'编码': 'handle', '文本': 'text', '手机号': 'phone'}

export let addTypeMap = {'add': 1, 'edit': 2}

export let inputTypeMap = {
    'text': '文本',
    'number': '数字',
    'nickname': '昵称',
    'phone': '手机号',
    'car': '车牌号',
    'name': '姓名',
    'idcard': '身份证号',
    'location': '定位',
    'address': '位置',
    'endTime': '日期',
    'handle': '标识'
}


export let formTypeMap = {'0': '状态选择', '1': '输入框', '2': '图片', '3': '文本域'}


export let picTypeMap = {'0': '不限制', '1': '4:3', '2': '1:1'}


export let defaultJson = [[{
    pic: '/ryt/state.png',
    "typeTwo": 0,
    "type": 0,
    content: {
        "item": [{
            "title": "正常",
            "iconColor": "#333",
            "checked": true,
            "style": {"color": "green"}
        }, {"title": "异常", "iconColor": "#333", "checked": false, "style": {"color": "red"}}]
    }
}],
    [{
        pic: '/ryt/title.png', "typeTwo": 0, content: {"title": "标题"},"type": 1
    }],
    [{
        pic: '/ryt/btn.png',"type": 2, "typeTwo": 2, content: [{
            "icon": "mktpic/xj%402x.png",
            "permission": true,
            "title": "巡检",
            "notify": "3",
            "formList": {
                "list": [{
                    "require": false,
                    "iType": "nickname",
                    "title": "昵称",
                    "type": 1,
                    "fit": 0
                }, {
                    "require": false,
                    "iType": "phone",
                    "title": "手机号码",
                    "type": 1,
                    "fit": 0
                }, {
                    "require": false,
                    "type": 1,
                    "title": "巡检内容",
                    "iType": "text",
                    "fit": 0,
                    "value": "设备运行正常"
                }, {
                    "proportion": 2,
                    "count": 3,
                    "require": "false",
                    "type": 2,
                    "title": "现场图片",
                    "fit": 0,
                    "imgList": [{}, {}, {}]
                }], "hideArray": [0, 0], "stateArray": [0, 0]
            },
            "btnType": 1
        }, {
            "icon": "mktpic/bx%402x.png",
            "permission": false,
            "title": "报修",
            "notify": -1,
            "hide": "设备状态/异常",
            "formList": {
                "list": [{
                    "require": false,
                    "iType": "nickname",
                    "title": "昵称",
                    "type": 1,
                    "fit": 0
                }, {
                    "require": false,
                    "iType": "phone",
                    "title": "手机号码",
                    "type": 1,
                    "fit": 0
                }, {"require": false, "type": 1, "title": "故障说明", "iType": "text", "fit": 0}, {
                    "proportion": 2,
                    "count": 3,
                    "require": "false",
                    "type": 2,
                    "title": "现场图片",
                    "fit": 0,
                    "imgList": [{}, {}, {}]
                }], "hideArray": [0, 0], "stateArray": [1, 1]
            },
            "btnType": 1
        }, {
            "icon": "mktpic/wx%402x.png",
            "permission": true,
            "title": "维修",
            "notify": -1,
            "hide": "设备状态/正常",
            "formList": {
                "list": [{
                    "require": false,
                    "iType": "nickname",
                    "title": "昵称",
                    "type": 1,
                    "fit": 0
                }, {"require": false, "iType": "phone", "title": "手机号码", "type": 1, "fit": 0}, {
                    "type": 1,
                    "title": "维修说明",
                    "iType": "text",
                    "fit": 0
                }, {
                    "proportion": 2,
                    "count": 3,
                    "require": "false",
                    "type": 2,
                    "title": "现场图片",
                    "imgList": [{}, {}, {}]
                }], "hideArray": [0, 0], "stateArray": [1, 0]
            },
            "btnType": 1
        }, {
            "icon": "mktpic/by%402x.png",
            "permission": true,
            "title": "保养",
            "notify": "5",
            "formList": {
                "list": [{
                    "require": false,
                    "iType": "nickname",
                    "title": "昵称",
                    "type": 1,
                    "fit": 0
                }, {"require": false, "iType": "phone", "title": "手机号码", "type": 1, "fit": 0}, {
                    "type": 1,
                    "title": "运行情况",
                    "iType": "text",
                    "fit": 0
                }, {
                    "proportion": 2,
                    "count": 3,
                    "require": "false",
                    "type": 2,
                    "title": "现场图片",
                    "imgList": [{}, {}, {}]
                }], "hideArray": [0, 0], "stateArray": [0, 0]
            },
            "btnType": 1
        }]
    }], [{
        pic: '/ryt/table.png',
        "typeTwo": 2,
        "type": 3,
        content: {
            "isBorder": true,
            "dataList": [{"iType": "handle", "type": 1, "key": "设备编码"}, {
                "iType": "text",
                "key": "电机功率"
            }, {
                "iType": "text",
                "key": "工作电压"
            }, {"key": "工作单元"}, {"key": "搅拌转速"}, {"key": "洗涤时间"}, {"key": "温度范围"}],
            "style": {
                "right": {"color": "#333", "fs": "18px", "ta": "left", "lh": "30px"},
                "left": {"color": "#333", "fs": "18px", "ta": "left", "lh": "30px"}
            }
        }
    }],
        [{
        pic: '/ryt/pic.png',
            "type": 4,
        "typeTwo": 2,
            content: {}
    }],
        [{
        pic: '/ryt/txt.png',
        "typeTwo": 2,
            content: {}
    }],
    [{
        pic: '/ryt/record.png',
        "type": 6,
        "typeTwo": 0,
        content: {max: 2}
    }]
]

export function addRytItems(param, itemData) {
    addRytItem(param.addType, param.type, param.typeTwo, itemData, param.pIndex, param.index)
}

//添加任意贴页面组件
export function addRytItem(addType, type, typeTwo, content, pIndex, index) {
    console.log(addType, type, typeTwo)
    let pages = getCurrentPages()
    let prevPage = pages[pages.length - 2]
    let curPage = pages[pages.length - 1];
    console.log('zhixing', addType, addTypeMap.add)
    if (addType === addTypeMap.add) {
        console.log('添加', prevPage)
        prevPage.$vm.addItem({type: type, typeTwo: typeTwo, content: content}, pIndex, index)
    } else if (addType === addTypeMap.edit) {
        console.log('编辑', pIndex, index)
        prevPage.$vm.addItem({type: type, typeTwo: typeTwo, content: content}, pIndex, index)
    }
    uni.navigateBack({
        delta: 1 // 返回的页面数
    })
}

export function getPrePage() {
    let pages = getCurrentPages()
    return pages[pages.length - 2]
}


export function getStateList(firstValue) {
    var firstList = ['不做任何改变']
    var stateList = getStorage('stateList')
    stateList.forEach(item => {
        firstList.push(item.title)
    })
    return [firstList, ['不改变']]
}


