<template>
  <div>
    <div class="myTitle" :style="[itemData.style]" v-if="!isEdit">{{ itemData.title }}</div>
    <input class="myTitle" :style="[itemData.style]" :value="itemData.title" v-else @input="valueChange($event,'title')"/>
  </div>

</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    pId:{
      type: Number
    },
    cId:{
      type: Number,
      default: null
    }
  },
  data() {
    return {
    }
  },
  methods:{
    valueChange(e,key){
       console.log('cids',e.detail.value,key,this.pId,this.cId)
       this.$emit('valueChange',this.pId,this.cId,[key],e.detail.value)
    }
  },
}
</script>
<style scoped>
.myTitle {
  margin-bottom: 10px;
  background: transparent !important;
  line-height: 30px;
  font-weight: bold;
   padding: 25px 0px 20px 15px
}
</style>
