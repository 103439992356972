<template>
  <div>
    <div class="state_con2">
      <div class="state_desc2">
        <div :style="[getDefaultItem(itemData).style]">
          <span style="margin-right: 10rpx;">•</span>
          {{getDefaultItem(itemData).title}}
        </div>
      </div>
      <div class="stateUpdate">{{getLastData()}}更新</div>
    </div>
  </div>
</template>
<script>

export  default {
  data(){
    return{

    }
  },
  props:{
    itemData:{
      type: Object,
      default: ()=>{
        return {}
      }
    },
    'dataList':{
      type: Array
    },
    showEditBtn: {
      type: Boolean,
      default: false
    },
    pId:{

    },
    pageModeType: {
      type: String
    },
    historyList: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  methods:{
    getDefaultItem(item){
      let defaultItem = {}
      item.item.forEach(subItem=>{
        if(subItem.checked&&subItem.title){
          defaultItem = subItem
        }
      })
      return defaultItem;
    },
    goAddZj() {
			this.$emit('goAddZj', this.itemData, this.pId)
		},
    getLastData(){
      if(this.historyList.length>0){
        return this.historyList[0].nickeName+' '+this.historyList[1].createTime
      }else {
        return  "XXX YYYY-MM-dd HH:mm:ss"
      }
    }
  }
}
</script>
<style scoped>

.state_desc2{
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2B2F34;
}
.stateUpdate{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #77849F;
  padding-top: 8px;
}
</style>
