<template>
  <el-dialog :visible.sync="dialogVisible"  :modal-append-to-body='false' width="60%">
    <el-row :gutter="50">
      <el-col :span="8">
        <el-card>
          <el-form label-width="80px">
             <el-form-item v-for="(item,index) in form.list" :key="index" :label="item.title">
                 <el-input :value="item.title" style="flex: 1" placeholder="key值" disabled></el-input>
             </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card>
          <el-scrollbar  style="height: 100%;">
          <el-form label-width="80px">
            <div v-for="(item,index) in form.list" :key="index">
              <el-form-item label="类型">
                <div style="display: flex">
                  <el-radio-group v-model="item.type" style="flex: 1">
                    <el-radio-button v-for="(value,key) in formTypeMap" :label="Number(key)" :key="key">{{value}}</el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
                <el-form-item label="标题" v-if="item.type===1||item.type===3">
                  <div style="display: flex">
                    <el-input style="flex: 1" v-model="item.title"></el-input>
                    <el-input :value="item.value" style="flex: 1" placeholder="默认值"></el-input>
                    <el-checkbox v-model="item.require">必填</el-checkbox>
                  </div>
                </el-form-item>
                <el-form-item label="类型" v-if="item.type===1">
                  <div style="display: flex">
                    <el-radio-group v-model="item.iType" style="flex: 1">
                      <el-radio-button v-for="(value,key) in inputTypeMap" :label="key" :key="key">{{value}}</el-radio-button>
                    </el-radio-group>
                    <el-button type="text" style="color: red" @click="delLine(index)">删除</el-button>
                  </div>
                </el-form-item>
              <el-row  v-if="item.type===2">
                <el-col :span="10">
                  <el-form-item label="标题">
                      <el-input style="flex: 1" v-model="item.title"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="最多张数" v-if="item.type===2">
                      <el-input :value="item.count" style="flex: 1" placeholder="最多张数"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-checkbox v-model="item.require">必填</el-checkbox>
                </el-col>
                <el-col :span="24">
                <el-form-item label="图片比例">
                  <el-radio-group v-model="item.proportion">
                    <el-radio-button v-for="(value,key) in picTypeMap" :label="key" :key="key">{{value}}</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="item.type===0">
                 <el-col :span="12">
                   <el-form-item label="状态类型">
                     <el-select v-model="item.state" placeholder="请选择" @change="stateChange($event,item)">
                       <el-option
                           v-for="(stateItem,index) in formStateArray"
                           :key="stateItem.value"
                           :label="stateItem.label"
                           :value="index">
                       </el-option>
                     </el-select>
                   </el-form-item>
                 </el-col>
                <el-col :span="12">
                  <el-form-item label="标题">
                      <el-input v-model="item.title" placeholder="标题"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                   <el-form-item label="默认值" v-if="item.state!==undefined">
                     <el-select v-model="item.value" placeholder="请选择" clearable>
                       <el-option
                           v-for="(stateItem,index) in formStateArray[item.state].children"
                           :key="stateItem.value"
                           :label="stateItem.label"
                           :value="index">
                       </el-option>
                     </el-select>
                   </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
            </div>
          </el-form>
            <el-button @click="addLine">添加一行</el-button>
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
  </el-dialog>



</template>
<script>
import {formTypeMap, inputTypeMap, picTypeMap} from "@/utils/ryt";

export  default {
  data(){
    return {
      dialogVisible: false
    }
  },
  computed: {
    picTypeMap() {
      return picTypeMap
    },
    formTypeMap() {
      return formTypeMap
    },
    inputTypeMap() {
      return inputTypeMap
    },
    formStateArray(){
      return this.stateArray.filter((item,index)=>{return index!==0})
    }
  },
   props:{
     form:{
       type: Object,
       default: ()=>{
         return {}
       }
     },
     stateArray: {
       type: Array,
       default: ()=>{
         return []
       }
     }
   },
  mounted() {
    console.log('stateAer',this.stateArray)
  },
  methods:{
    stateChange(e,item){
        item.title=this.stateArray[e+1].label
       console.log('状态',e,item)
    },
    addLine(){
      this.form.list.push({})
      console.log('foem',this.form)
    },
    delLine(index){
      this.form.list.splice(index,1)
    }
  }
}
</script>