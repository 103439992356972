<template>
  <div>
    <el-form-item label="类型">
      <div style="display: flex">
        <el-radio-group v-model="item.iType" style="flex: 1">
          <el-radio-button v-for="(value,key) in inputTypeMap" :label="key" :key="key">{{value}}</el-radio-button>
        </el-radio-group>
        <el-button type="text" style="color: red" @click="delLine(index)">删除</el-button>
      </div>
    </el-form-item>
    <el-form-item label="标题">
      <div style="display: flex">
        <el-input style="flex: 1" v-model="item[itemKey]"></el-input>
        <el-checkbox v-model="item.require">必填</el-checkbox>
      </div>
    </el-form-item>
    <el-form-item label="默认值">
      <div style="display: flex">
        <el-input v-model="item.value" style="flex: 1" placeholder="默认值"></el-input>
      </div>
    </el-form-item>
  </div>
</template>
<script>
import {formTypeMap, inputTypeMap} from "@/utils/ryt";

export  default {
  computed: {
    formTypeMap() {
      return formTypeMap
    },
    inputTypeMap() {
      return inputTypeMap
    }
  },
  props:{
    item:{

    },
    index: {

    },
    itemKey: {
      type: String,
      default: 'key'
    }
  },
  methods:{
    delLine(index){

    }
  }
}
</script>