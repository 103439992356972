<template>
   <div class="recordWrap">
    <div class="disflex">
      <div class="flex1">{{itemData.title}}</div>
    </div>
    <div class="item-list">
      <template v-if="historyList.length">
       <view v-for="item in historyList.slice(0, (itemData && itemData.max ? itemData.max : 2))" :key="item.id" class="item-list-item" @click="goDetail(item)">
         <div class="item-top">{{item.state}}</div>
          <div class="item-text"><span>{{item.createTime}}</span><span>{{item.nickeName}}提交</span></div>
       </view>
      </template>
      <template v-else>
         <div class="noDataBox">暂无数据</div>
      </template>
    </div>
    <div class="btnsBox">
      <div class="btn1" v-if="!isSubscribe" @click="dyHandle"><image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/dy%402x.png" class="BtnIcon" />订阅动态</div>
      <div class="btn1 btn3" v-else @click="cancelSubscribe"><image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/ryt/qxdy%402x.png" class="BtnIcon" />取消订阅</div>
      <div class="btn2" @click="goHistory"><image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mktpic/jl%402x.png" class="BtnIcon"  />全部记录</div>
    </div>
  </div>
 
</template>
<script>
  import {get, post} from "@/utils/request";
  export default {
    data() {
      return {
        list: [],
        show: true
      }
    },
    props: {
      itemData: {
        type: Object
      },
      pageId: {
        type: [String, Number],
        default: '',
      },
      isSubscribe: {

      },
      historyList:{
        type: Array,
        default: ()=>{
          return []
        }
      },
      pageModeType:{
        
      },
      isAdmin:{

      }
    },
    created() {
      // this.getData()
      if(this.pageModeType === 'look' && !this.isAdmin) {
        this.show = false
      }
    },
    methods: {
      // getData() {
      //   let params = {
      //     pageId: this.pageId,
      //     pageNum: 1,
      //     pageSize: this.itemData.max,
      //     type: 1, // 0 扫码记录 1 操作记录
      //   }
      //   let that = this
      //   get('/ryt/logList', params).then(res => {
      //     that.list = res.list
      //   })
      // },
      dyHandle() {
        this.$emit('subscriptionMethod')
      },
      cancelSubscribe() {
        this.$emit('cancelSubscribe')
      },
      goHistory() {
        if(this.pageModeType && this.pageModeType === 'editPage') return 
        uni.navigateTo({url:'/pages/ryts/history?pageId='+this.pageId})
      },
      // 跳转详情页
      goDetail(item) {
        uni.navigateTo({
          url: `/pages/ryts/detail?id=${item.id}`,
        })
      },
    }
  }
</script>
<style scoped>
.item-list-item{
  /* width: 672rpx; */
  height: 72px;
  background: #FAFCFF;
  border: 1px solid #DFE7EE;
  border-radius: 10rpx 10rpx 20rpx 10rpx;
  box-sizing: border-box;
  padding: 15px 8px;
  margin: 0 auto 10px;
}
.item-list-item .item-top {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2B2F34;
}
.item-list-item .item-text{
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #828B95;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.btnsBox{
  font-size: 14px;
  font-family: Source Han Sans CN;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 45px;
  display: flex;
  justify-content: flex-start;
}
.btnsBox .btn1{
  color: #3466FF;
  width: 124px;
  height: 45px;
  border: 1px solid #3466FF;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  box-sizing: border-box;
}
.btnsBox .btn2{
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  background: #3466FF;
  width: 201px;
  height: 45px;
  background: #3466FF;
  border-radius: 10px;
  text-align: center;
}
.btnsBox .btn3{
    border: 1px solid #CBCFD9;
    color: #8B949D;
}
.BtnIcon{
  width: 11px;
  height: 15px;
  vertical-align: middle;
  margin-right: 6px;
}
.recordWrap{
  border-bottom: 2px solid #F2F5FA;
  margin: 0 0px 0 0px;
  padding: 0 15px 20px 15px;
  background: #fff;

}
.noDataBox{
  text-align: center;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #77849F;
  padding: 20px 0 30px 0;
}
</style>