<template>
  <div class="table">
        <div class="cloumn" v-for="(item,index) in itemData.dataList" :key="index">
            <div class="title">{{ item.key }}</div>
            <div class="con">
              <template v-if="item.iType === 'phone'">
                <span @click="callPhone(item.value)" class="blueText">{{item.value}}</span>
              </template>
              <template v-else-if="item.iType === 'handle'">
                <span class="blueText" @click="goHandlePage">{{handle}}</span>
              </template>
              <template v-else-if="item.iType === 'endTime'">
                <span :class="{'redText': getTextColor(item.value)}">{{item.value}}</span>
              </template>
              <template v-else>
                {{item.value ? item.value : '---'}}
              </template>
              </div>
        </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
  	return {
      color: 'red',
		myStyle:{
      '--lSize': this.itemData.style.left['fs'],
      '--lLh': this.itemData.style.left['lh'],
      '--lTa': this.itemData.style.left['ta'],
      '--rSize': this.itemData.style.right['fs'],
      '--rLh': this.itemData.style.right['lh'],
      '--rTa': this.itemData.style.right['ta'],
      '--lColor': this.itemData.style.left['color'],
      '--rColor': this.itemData.style.right['color'],
    }
	}
  },
  props: {
    itemData: {
      type: Object,
      default: ()=>{
        return [];
      }
    },
    isEdit:{
      type:Boolean,
      default: false
    },
    pId:{
      type: Number
    },
    cId:{

    },
    handle: {

    },
    pageModeType: {

    }
    
  },
  watch:{
      'itemData':{
        handler(val,oldVal){
          this.$set(this,'myStyle',{
            '--lSize': this.itemData.style.left['fs'],
            '--lLh': this.itemData.style.left['lh'],
            '--lTa': this.itemData.style.left['ta'],
            '--rSize': this.itemData.style.right['fs'],
            '--rLh': this.itemData.style.right['lh'],
            '--rTa': this.itemData.style.right['ta'],
            '--lColor': this.itemData.style.left['color'],
            '--rColor': this.itemData.style.right['color'],
          })
        }
      }
  },
  computed:{

  },
  mounted(){
    console.log('当前数据',this.itemData)
  },
  methods: {
    goHandlePage() {
      uni.navigateTo({
        url: `/pages/handlePage/main?identity=${this.handle}`
      })
    },
    callPhone(phone) {
      if(phone) {
        wx.makePhoneCall({
          phoneNumber: phone //仅为示例，并非真实的电话号码
        })
      }
    },
    titleChange(index,value){
      let realKey=['dataList',''+index,'key']
      if(this.cId>=0){
        let realKey=['content','dataList',''+index,'key']
      }
      console.log(realKey,value)
      this.$emit('valueChange',this.pId,this.cId,realKey,value)
    },
    tableValueChange(index,value){
      let realKey=['dataList',''+index,'value']
      if(this.cId>=0){
        let realKey=['content','dataList',''+index,'value']
      }
      this.$emit('valueChange',this.pId,this.cId,realKey,value)
    },
    valueChange(e,index,key){
      let realKey=['dataList',''+index,key+'']
      if(this.cId>=0){
        let realKey=['content','dataList',''+index,key+'']
      }
      //let  realKey='[content[dataList]]'
       this.$emit('valueChange',this.pId,this.cId,realKey,e.detail.value)
    },
    // 编辑模式
    goAddZj() {
      uni.navigateTo({
          url: '/pages/handle/index'
        })
    },
    // 过期时间，快到期颜色是红色
    getTextColor(date) {
      let res = true
      //let m1 = moment(date)
     // let m2 = moment()
     // let day = m2.diff(m1, 'day')
      console.log('day',day)
      return res
    },
    addItem(index){
      // [{'key': '标题1',value: '',iType:'text'}]
      // console.log('当前样式',this.content.style)
      this.$emit('addItem')
      //this.dataList.push(this.getNullItem())
    },
    delItem(index){
      this.$emit('delItem',index)
    },
    edit(index){
      // uni.navigateTo({
      //   url: `/pages/ryt/inputEdit?index=${index}&from=modelTableEdit`
      // })
    },
    editDialog(pId,index,cIndex,data) { 

    }
  }
}

</script>
<style lang="scss" scoped>
.table {
  // border-top: 1px solid #333;
  padding: 10px;
  // background-color: var(--color);
  background: #FAFCFF;
  border-radius: 5px 5px 10px 5px;
  border: 1px solid #DFE7EE;
  // width: 672rpx;
  margin: 0 15px 10px 15px;
  box-sizing: border-box;
}


.cloumn {
  display: flex;
  line-height: 30px;
  font-size: 14px;
  // border-bottom: 1px solid #333;
  // border-left: 1px solid #333;
  // border-right: 1px solid #333;
  padding: 5px 0 5px;
  border-bottom: 1px solid #DFE7EE;
}
.table .cloumn:last-child{
  border: none;
}

.title {
  width: 80px;
  padding-left: 10px;
  font-size: var(--l-size);
  line-height: var(--l-lh);
  text-align: var(--l-ta);
  // color: var(--l-color);
  color:'#828B95';
}

.con {
  flex: 1;
  // border-left: 1px solid #333;
  padding-left: 10px;
  font-size: var(--r-size);
  line-height: var(--r-lh);
  text-align: var(--r-ta);
  color: var(--r-color);
}
.conEdit{
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 3px 5px;
}

.blueText{
  color: #3466FF;
}
.redText{
  color: #f00;
}
.addRowBtn{
  font-weight: 400;
  color: #2B64F8;
  line-height: 100rpx;
  width: 649rpx;
  height: 100rpx;
  border: 1px solid #2B64F8;
  border-radius: 20rpx;
  font-size: 30rpx;
  font-family: PingFang SC;
  margin-top: 53rpx;
  background: #fff;
}
.addIcon{
  width: 36rpx;
  height: 36rpx;
  margin-right: 14rpx;
  line-height: 100rpx;
  vertical-align: middle;
}
</style>
