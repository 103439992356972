<template>
   <div class="disflex">
     <div class="ryt-label" v-if="editTile">
       <input :value="title" @input="inputChange"/>
     </div>
     <div class="ryt-label" v-else-if="title">
       {{title}}
       </div>
     <div class="flex1">
       <div  v-if="imgSrc" class="img-con" :style="[itemData && itemData.style]">
         <image :src="String(imgSrc).indexOf('http')===-1?baseImageUrl+imgSrc:imgSrc" :style="[imgStyle]" mode="widthFix" class="img" @click="showPreview(imgSrc)"></image>
         <div v-if="isEdit" class="change-img" @click="changeImg">
           <image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/ryt/icon_fm.png" class="addIcon" />
         </div>
       </div>
       <template v-else>
         <template v-if="pageModeType!=='look'">
            <div :style="[itemData.style]" class="up_con">
              <div @click="changeImg">
                <!-- <text class="icon iconfont icon-tianjia"/> -->
                  <image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/ryt/upImageIcon.png" class="addIcon" />
                <div class="add_txt">(建议大小{{size ? size : 500}}KB以下)</div>
              </div>
            </div>
         </template>
       </template>
     </div>
   </div>
</template>
<script>

import {baseImageUrl, chooseImg} from "@/utils/utils";
import  {previewImage} from "@/utils/utils";
 export default {
   data(){
     return {
       baseImageUrl: baseImageUrl,
       size: 500,
     }
   },
   mounted() {

   },
   components: {},
   props: {
     editTile:{
       type:Boolean,
       default: false
     },
     title:{

     },
     isEdit:{
       type: Boolean
     },
     imgSrc:{},
     imgStyle:{

     },
     pageModeType: {
       
     },
     itemData:{

     },
     from: {
     },
     pageId:{

     },
     width: {
       type: Number,
     }
   },
  //  watch:{
  //    imgSrc: {
  //      handler(newValue, oldValue) {
  //         // console.log(newValue, oldValue)
  //         // console.log('imgSrc')
  //       },
  //       deep: true,
  //       immediate: true
  //    }
  //  },
   methods:{
    
     changeImg(){
       if(this.pageModeType && this.pageModeType === 'look') {
         return 
       }
       chooseImg(1,['image'],this.pageId).then(res=>{
         this.$emit('valueChange',res[0])
       })
     },
     inputChange(e){
       this.$emit('titleChange',e.detail.value)
     },
     showPreview(imgSrc) {
      previewImage(imgSrc.indexOf('http')===-1?baseImageUrl+imgSrc:imgSrc)
    }
   }
 }
</script>
<style scoped>

.img{
  width: auto;
  display: block;
  /* height: 250rpx; */
}

 .img-con{
   position: relative;
 }

 .change-img{
   position: absolute;
   top: 5px;
   right: 5px;
 }

 .ryt-label{
   margin-top: 10px;
 }
 .img-con{
   width: 100%;
 }
 .addIcon{
  width: 25px;
  height: 25px;
  margin-bottom: 10px;
}
.up_con{
  text-align: center;
  background: rgba(43,100,248,0.01);
  padding: 45px 0!important;
  border: none;
  border: 1px dashed #C3C9DC;
}
.add_txt{
  font-size: 12px;
  font-family: PingFang SC;
  color: #8F9AB4;
}
</style>
