<template>

  <div class="contentPage">

    <el-row :gutter="100">
      <el-col :span="8">
         <el-card>
           <el-row>
             <el-col :span="8">
               <el-menu :default-active="'1'" @select="menuSelect">
                 <el-menu-item :index="key" v-for="(value,key) in componentTypeMap" :key="key">
                   <span slot="title">{{value}}</span>
                 </el-menu-item>
               </el-menu>
             </el-col>
             <el-col :span="16">
                <div v-for="(item,index) in defaultJson()[curComponentSel-1]" :key="index" @click="addComponent(item)">
                  <el-card>
                    <img :src="baseImgUrl()+item.pic" style="width: 100%"/>
                  </el-card>

                </div>
             </el-col>
           </el-row>

         </el-card>
      </el-col>
        <el-col :span="7">
         <div class="centerBox">
           <el-card>
             <el-scrollbar class="modelContentComponentWrap">
               <div v-for="(item,index) in modelData.itemList" :key="index" :class="curSelIndex===index?'item-sel':''" @click.stop="getcurSelIndex(index)">
                 <my-title-view v-if="item.type===typeMap.title" :item-data="item.content"/>
                 <state-view-component v-if="item.type===typeMap.state" :itemData="item.content"></state-view-component>
                 <button-view2-component v-if="item.type===typeMap.btn" :itemData="item.content"></button-view2-component>
                 <!--              <div v-if="item.type===typeMap.record">{{item.content}}</div>-->
                 <record-view-component v-if="item.type===typeMap.record" :itemData="item.content"/>
                 <table-view2-component  v-if="item.type===typeMap.table" :itemData="item.content"/>
                 <pic-view-component v-if="item.type===typeMap.pic" :itemData="item.content"/>
                 <manage-view-component v-if="item.type===typeMap.pic" :itemData="item.content"/>
               </div>
             </el-scrollbar>
           </el-card>
         </div>
        </el-col>
      <el-col :span="9">
        <div class="centerBox">
        <el-card>
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="saveModel">保存</el-button>
          </div>
          <el-button @click="delItem(curSelIndex)">删除此项</el-button>
          <div style="padding: 20px" v-if="modelData.itemList.length>0">
             {{curItem}}
          <el-form label-width="100px">
            <div  v-if="modelData.itemList[curSelIndex].type===typeMap.title">
              <el-form-item label="标题">
                <el-input placeholder="请输入" v-model="modelData.itemList[curSelIndex].content.title"></el-input>
              </el-form-item>
            </div>
            <div v-if="modelData.itemList[curSelIndex].type===typeMap.state">
              <el-form-item label="状态" v-for="(item,index) in modelData.itemList[curSelIndex].content.item" :key="index">
                <div style="display: flex">
                  <el-input placeholder="请输入" v-model="item.title" style="flex: 1"></el-input>
                  <el-radio v-model="item.checked" :label="true" @input="stateRadioChange(index)">默认</el-radio>
                  <el-color-picker v-model="item.style.color"></el-color-picker>
                  <el-button type="text" @click="delStateItem(index)">删除</el-button>
                </div>
              </el-form-item>
              <el-button @click="addStateItem">新增</el-button>
            </div>
            <div v-if="modelData.itemList[curSelIndex].type===typeMap.btn">
               <button-view2-component :item-data="modelData.itemList[curSelIndex].content" @btnClick="btnClick"/>
              <el-form-item label="标题">
                <div style="display: flex">
                  <el-input placeholder="请输入" v-model="curBtn.title" style="flex: 1"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="通知周期">
                <div style="display: flex">
                  <el-input placeholder="请输入" v-model="curBtn.notify" style="flex: 1"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="隐藏条件">
                <el-cascader
                    v-model="curBtn.formList.hideArray"
                    :options="stateArray"></el-cascader>
              </el-form-item>
              <el-form-item label="权限申请">
                <el-switch
                    v-model="curBtn.require"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
                <el-button @click="formEdit" style="float: right">表单编辑</el-button>
              </el-form-item>
               <el-button @click="btnAdd">新增</el-button>
              <el-button @click="btnDel">删除</el-button>
            </div>
            <div v-if="modelData.itemList[curSelIndex].type===typeMap.record">
              <el-form-item label="显示条数">
              <el-input placeholder="显示条数" v-model="modelData.itemList[curSelIndex].content.max"></el-input>
              </el-form-item>
            </div>
            <div v-if="modelData.itemList[curSelIndex].type===typeMap.table">
              <div v-for="(item,index) in modelData.itemList[curSelIndex].content.dataList" :key="index">
                <ryt-input-edit :item="item" :index="index"/>


<!--                <el-form-item label="标题" >-->
<!--                  <div style="display: flex">-->
<!--                  <el-input placeholder="请输入" v-model="item.key" style="flex: 1"></el-input>-->
<!--                  <el-radio-group v-model="item.iType">-->
<!--                    <el-radio-button :label="key" v-for="(key,radioItem) in tableTypeMap" :key="key">{{radioItem}}</el-radio-button>-->
<!--                  </el-radio-group>-->
<!--                  </div>-->
<!--                </el-form-item>-->
<!--                  <el-form-item label="默认值" v-if="item.iType!==tableTypeMap.编码">-->
<!--                    <div style="display: flex">-->
<!--                    <el-input placeholder="请输入" v-model="item.title" style="flex: 1"></el-input>-->
<!--                    <el-button @click="delTableLine(index)" type="text">删除</el-button>-->
<!--                    </div>-->
<!--                  </el-form-item>-->
                <el-divider></el-divider>
              </div>
               <el-button @click="addTableLine">新增</el-button>
            </div>
            <div v-if="modelData.itemList[curSelIndex].type===typeMap.pic">
              <el-form-item label="默认图片">
                <el-upload
                    :action="actionMethod"
                    :headers="upParams"
                    list-type="picture-card"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </div>
          </el-form>
          </div>
        </el-card>
        </div>
      </el-col>
    </el-row>
    <ryt-form-edit :form="rytForm" ref="formEdit" :state-array="stateArray"/>

    <comm-dialog ref="saveModel" @confirm="saveConfirm">
      <template slot="content">
        <el-form label-width="100px">
          <el-form-item label="模板名称">
            <el-input placeholder="请输入模板名称" v-model="modelData.modelName"/>
          </el-form-item>
          <el-form-item label="缩略图">

          </el-form-item>
          <el-form-item label="查看申请">
            <div style="display: flex">
              <el-switch style="flex: 1" v-model="modelData.needApply"/>
              <el-button @click="seeFormEdit" v-if="modelData.needApply">表单编辑</el-button>
            </div>
          </el-form-item>
          <el-form-item label="操作申请">
            <div style="display: flex">
              <el-switch style="flex: 1" v-model="modelData.needBtnPermission"/>
              <el-button @click="applyFormEdit" v-if="modelData.needBtnPermission">表单编辑</el-button>
            </div>
          </el-form-item>
        </el-form>
      </template>
    </comm-dialog>

<!--    <el-dialog :visible.sync="saveDialogVisible" :modal-append-to-body='false' width="40%">-->
<!--       -->
<!--    </el-dialog>-->
  </div>
</template>
<script>
import {get} from "@/utils/request";
import StateViewComponent from "@/pages/ryt/components/edit/StateViewComponent.vue";
import MyTitleView from "@/pages/ryt/components/edit/MyTitleView.vue";
import RecordViewComponent from "@/pages/ryt/components/edit/RecordViewComponent.vue";
import ButtonView2Component from "@/pages/ryt/components/edit/ButtonView2Component.vue";
import {componentTypeMap, defaultJson, tableTypeMap, typeMap} from "@/utils/ryt";
import TableView2Component from "@/pages/ryt/components/edit/TableView2Component.vue";
import PicViewComponent from "@/pages/ryt/components/edit/PicViewComponent.vue";
import ManageViewComponent from "@/pages/ryt/components/edit/ManageViewComponent.vue";
import {baseUrl} from "@/utils";
import RytFormEdit from "@/pages/ryt/components/edit/RytFormEdit.vue";
import RytInputEdit from "@/pages/ryt/components/edit/RytInputEdit.vue";
import {baseImgUrl} from "@/utils/constant";
import CommDialog from "@/components/common/CommDialog.vue";

export default {
  computed: {
    componentTypeMap() {
      return componentTypeMap
    },
    tableTypeMap() {
      return tableTypeMap
    },
    typeMap() {
      return typeMap
    }
  },
  components: {
    CommDialog,
    RytInputEdit,
    RytFormEdit,
    TableView2Component, MyTitleView, StateViewComponent,RecordViewComponent,ButtonView2Component,PicViewComponent,ManageViewComponent},
  data() {
    let token = localStorage.getItem("token")
    return {
      saveDialogVisible: false,
      curFormList: [],
      actionMethod: baseUrl + '/api/file/uploadObjectOSS',
      modelData: {itemList: [],tableContent:{list: []},permissionContent:{list: []}},
      curSelIndex: 0,
      upParams: {
        token: token,
      },
      curBtnIndex: 0,
      curItem: {},
      curBtn: {},
      stateArray: [{value:0,label:'不改变',children: [{value:0,label:'不改变'}]}],
      curComponentSel: 1,
      rytForm: {}
    }
  },
  mounted() {
    //this.init();
    //console.log('det',defaultJson[1])
    this.addComponent(defaultJson[1][0])
    this.addComponent(defaultJson[0][0])
    this.addComponent(defaultJson[1][0])
    this.addComponent(defaultJson[6][0])
    this.addComponent(defaultJson[2][0])
    this.addComponent(defaultJson[1][0])
    this.addComponent(defaultJson[3][0])
    this.addComponent(defaultJson[4][0])

  },
  methods: {
    saveModel(){
      this.$refs.saveModel.dialogShow=true
    },
    saveConfirm(){
       console.log('保存',this.modelData)
    },
    delItem(index){
      this.modelData.itemList.splice(index,1)
    },
    addComponent(item){

      var object=Object.assign({},item)
      delete object.pic
     // console.log('新增',item,object)
      this.modelData.itemList.push(object)
    },
    baseImgUrl() {
      return baseImgUrl
    },
    defaultJson() {
      return defaultJson
    },
    menuSelect(index){
      this.curComponentSel=index
    },
    stateRadioChange(selIndex){
      this.curItem.content.item.forEach((item,index)=>{
         item.checked=selIndex===index
      })
    },
    addStateItem(){
      this.curItem.content.item.push({title:'正常',checked:false,style: {color: '#000000'}})
    },
    delStateItem(index){
      this.curItem.content.item.splice(index,1)
    },
    btnAdd(){
      this.curItem.content.push({ "icon": "mktpic/xj%402x.png", "permission": true, "title": "按钮", "notify": "-1", "formList": { "list": [], "hideArray": [ 0, 0 ], "stateArray": [ 0, 0 ] }})
    },
    btnDel(){
      this.curItem.content.splice(this.curBtnIndex,1)
    },
    addTableLine(){
      this.curItem.content.dataList.push({ "title": "标题", "iconColor": "#333", "checked": true, "style": { "color": "green" } })
    },
    delTableLine(index){
      this.curItem.content.dataList.splice(index,1)
    },
    getcurSelIndex(index) {
      this.curSelIndex = index
      this.curItem=this.modelData.itemList[index]
      if(this.modelData.itemList[index].type===typeMap.btn){
        this.curBtn=this.curItem.content[0];
      }
    },
    init() {
      get('ryt/detail?id=1722182247144079362').then(res => {
        res.content = JSON.parse(res.content)
        this.modelData = res
        res.content.forEach(item=>{
          if(item.type===typeMap.state){

             var children=[]
            item.content.item.forEach((child,index)=>{
              children.push({value:index,label: child.title})
            })
            this.stateArray.push({value: this.stateArray.length,label:item.content.title,children:children})
            console.log('state',this.stateArray)
          }
        })

        this.curItem=this.modelData.itemList[1]
        this.curBtn=this.modelData.itemList[2].content[0]
      })
    },
    formEdit(){
       this.rytForm=this.curBtn.formList
       console.log('brn',this.rytForm)
       this.$refs.formEdit.dialogVisible=true
    },
    seeFormEdit(){

      this.rytForm=this.modelData.tableContent
      console.log('查看',this.rytForm)
      this.$refs.formEdit.dialogVisible=true
    },
    applyFormEdit(){
       this.rytForm=this.modelData.permissionContent
       this.$refs.formEdit.dialogVisible=true
    },
    btnClick(index){
        this.curBtnIndex=index
        this.curBtn=this.curItem.content[index]

    }
  }
}
</script>
<style scoped>
.contentPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.centerBox {
  height: calc(100vh - 100px);
  overflow: auto;
}

.modelContentComponentWrap {
  background: linear-gradient(180deg, #EAF5FF, #fff);
  background-size: 100% 288px;
  background-repeat: no-repeat;
  height: 100%;
}


.item-sel{
  border:  1px  solid black;
}


</style>
<style>
::v-deep .el-tabs--left{
  height: 1000px !important;
}
</style>