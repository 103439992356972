<template>
 <div class="btnsWrap">
   <div class="disflex allbtnsWrap" @click="btnClick">
     <div class="flex1 btn-item pt10" :class="index===curEditIndex&&isEdit?'btn-sel':''" v-for="(item,index) in itemData" :key="item.title" @click="btnClick(index)">
       <img class="w50 h50" :src='baseImgUrl+item.icon'/>
       <div class="title">{{item.title}}</div>
     </div>
    </div>
     <div class="bhText" v-if="reason && reason!=='同意'">您的申请被驳回，驳回原因：{{reason}}</div>
  </div>
</template>
<script>

import  {baseImgUrl} from "@/utils/constant";

export default {
  data(){
    return {
      baseImgUrl: baseImgUrl,
      curIndex: 0
    }
  },
  components: {},
  props:{
    itemData:{
       type: Array,
      default: ()=>{
        return []
      }
    },
    isEdit:{
      type: Boolean
    },
    curEditIndex:{
      // type: Number,
      // default: 0
    },
    reason: {

    }
  },
  methods:{
    btnClick(index){
        this.$emit('btnClick',index)
    },
  }
}
</script>
<style scoped>
  .allbtnsWrap{
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;

    padding: 14rpx 0 17rpx;
    justify-content: flex-start;
  }
  .btnsWrap{
    border-bottom: 5rpx solid #F2F5FA;
  }
  .title{
    text-align: center;
    font-size: 15px;
    color: #010101;
  }

  .disflex{
    flex-wrap: wrap;
  }

  .btn-item{
    text-align: center;
    padding: 22rpx 20rpx;
    border-radius: 20rpx;
    min-width: 19%;
  }

  .btn-sel{
    background: #E3EBFA;
  }
  .bhText{
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f00;
    padding-left: 15px;
  }

  .disflex{
    display: flex;
  }

  .flex1{
    flex: 1;
  }

  .w50{
    width: 50px;
  }

  .h50{
    height: 50px;
  }
</style>
